import React from 'react';
import { createUseStyles } from 'react-jss';
import ImageRenderer from '../../../components/ImageRender';
import Title from '../../../components/Title';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ServiceWebDetail = () => {
  // 
  // ─── STYLE INITIALIZATION ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    wrapper: {
      "@media screen and (min-width:1024px)": {
        display: "flex",
        alignItems: "center",
        padding: "80px 80px 20px"
      }
    },
    wrapperReverse: {
      '@media screen and (min-width:1024px)': {
        flexDirection: 'row-reverse'
      }
    },
    image: {
      margin: "20px 20px 0",
      "@media screen and (min-width:1024px)": {
        width: '50%',
        minHeight: '700px',
        margin: 0,
        position: 'relative',
        "& img": {
          height: '700px'
        }
      }
    },
    detailContainer: {

    },
    detailContent: {
      padding: 20,
      "& ul": {
        listStyle: 'none',
        padding: 0,
        "& li": {
          display: 'flex',
          alignItems: 'center',
          marginTop: 12,
          "& svg": {
            width: 16,
            marginRight: 10,
            color: 'var(--primary)'
          }
        }
      },
      "@media screen and (min-width:1024px)": {
        padding: 40,
        "& h3": {
          marginTop: 40,
        },
        "& p": {
          maxWidth: 800
        }
      }
    }
  })
  const classes = useStyle()

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <>
    <div className={classes.wrapper}>
      <ImageRenderer url={`/assets/img/services/web/service`} classNameContainer={classes.image} alt={""} extension='jpg' />
      <div className={classes.detailContainer}>
        <div className={classes.detailContent}>
          <Title tag="h2" text_regular="CRÉATION DE" text_strong="SITE INTERNET" align="left" />
          <p>Un site internet est le premier support de communication entre votre client et votre entreprise. Il doit être le reflet de votre philosophie et à votre image. Nous passons plusieurs heures à comprendre comment traduire au mieux votre image de marque dans l’univers du web.</p>
          <p>Pour ce faire, nous développons les sites internet avec les technologies les plus adaptées pour votre projet. Nous mettons tout en œuvre pour respecter les normes actuelles et permettre aux sites d'être référencés au mieux lors d’une recherche Google.</p>
          <h3>NOUS APPLIQUONS LES DIFFÉRENTES NORMES : </h3>
          <ul>
            <li>
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="svg-inline--fa fa-check fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
              <span>PWA</span>
            </li>
            <li>
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="svg-inline--fa fa-check fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
              <span>Responsive design</span>
            </li>
            <li>
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="svg-inline--fa fa-check fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
              <span>Mobile First</span>
            </li>
            <li>
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="svg-inline--fa fa-check fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
              <span>Référencement naturel SEO</span>
            </li>
            <li>
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="svg-inline--fa fa-check fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
              <span>Optimisation des performances du site</span>
            </li>
            <li>
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="svg-inline--fa fa-check fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
              <span>Expérience utilisateur</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div className={classes.wrapper + ' ' + classes.wrapperReverse}>
      <ImageRenderer url={`/assets/img/services/web/service-webap`} classNameContainer={classes.image} alt={""} extension='jpg' />
      <div className={classes.detailContainer}>
        <div className={classes.detailContent}>
          <Title tag="h2" text_regular="APPLICATION" text_strong="WEB" align="left" />
          <p>Parce que le web est de plus en plus puissant, passer à de véritables applications web sur mesure vous permettra de travailler où vous le souhaitez. Les limites des applications web sont celles que vous vous mettez.</p>
          <p>Nous vous guiderons dans la conception de votre projet avec une approche où l’expérience utilisateur sera mise en avant. L'utilisation des technologies du moment vous permettra de toujours profiter de ce que le web a de mieux à vous offrir.</p>
          <h3>TECHNOLOGIES : </h3>
          <ul>
            <li>
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="svg-inline--fa fa-check fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
              <span>React.js</span>
            </li>
            <li>
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="svg-inline--fa fa-check fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
              <span>Next.js</span>
            </li>
            <li>
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="svg-inline--fa fa-check fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
              <span>API Node.js</span>
            </li>
            <li>
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="svg-inline--fa fa-check fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
              <span>PHP</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </>
};

export default ServiceWebDetail;