import React from 'react';
import { createUseStyles } from 'react-jss';
import ImageRenderer from '../components/ImageRender';
import Title from '../components/Title';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ProjectDescription = (props) => {
  let data = props.data;

  // 
  // ─── STYLE INITIALIZATION ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      overflow: 'hidden',
      padding: 20,
      '@media screen and (min-width:850px)': {
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center'
      },
      '@media screen and (min-width:1024px)': {
        padding: '40px 20px',
      }
    },
    image: {
      "& img": {
        objectFi: 'initial',
        height: 'initial',
        transform: 'translateX(10%)'
      },
      '@media screen and (min-width:850px)': {
        minWidth: '45%',
        "& img": {
          transform: 'translateX(100px)'
        },
      },
      '@media screen and (min-width:1024px)': {
        width: '50%',
      }
    },
    content: {
      '@media screen and (min-width:850px)': {
        maxWidth: 750,
        margin: 'auto auto auto 40px'
      },
      '@media screen and (min-width:1024px)': {
        margin: 'auto',
        maxWidth: 500
      }
    },
    infosContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
      gap: 20,
      maxWidth: 1000,
      margin: 'auto',
      padding: '40px 0 60px',
      '@media screen and (min-width:1024px)': {
        padding: '40px 0 100px',
      },
    },
    infosContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '@media screen and (min-width:1024px)': {
        fontSize: 18
      },
      '& svg': {
        height: 18,
        fill: 'var(--primary)',
        marginRight: 10
      }
    },
    infosLink: {
      color: '#000'
    }
  })
  const classes = useStyle()

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div>
    <div className={classes.container}>
      <ImageRenderer classNameContainer={classes.image} url={`/assets/img/projects/${data.username}_mokup`} alt={`Site internet ${data.domain}`} extension='png' />
      <div className={classes.content}>
        <Title tag="h2" text_regular="À PROPOS" text_strong="DU PROJET" align="left" />
        <p>{data.description}</p>
      </div>
    </div>
    <div className={classes.infosContainer}>
      <div className={classes.infosContent}>
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="code" className="svg-inline--fa fa-code fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M278.9 511.5l-61-17.7c-6.4-1.8-10-8.5-8.2-14.9L346.2 8.7c1.8-6.4 8.5-10 14.9-8.2l61 17.7c6.4 1.8 10 8.5 8.2 14.9L293.8 503.3c-1.9 6.4-8.5 10.1-14.9 8.2zm-114-112.2l43.5-46.4c4.6-4.9 4.3-12.7-.8-17.2L117 256l90.6-79.7c5.1-4.5 5.5-12.3.8-17.2l-43.5-46.4c-4.5-4.8-12.1-5.1-17-.5L3.8 247.2c-5.1 4.7-5.1 12.8 0 17.5l144.1 135.1c4.9 4.6 12.5 4.4 17-.5zm327.2.6l144.1-135.1c5.1-4.7 5.1-12.8 0-17.5L492.1 112.1c-4.8-4.5-12.4-4.3-17 .5L431.6 159c-4.6 4.9-4.3 12.7.8 17.2L523 256l-90.6 79.7c-5.1 4.5-5.5 12.3-.8 17.2l43.5 46.4c4.5 4.9 12.1 5.1 17 .6z"></path></svg>
        <span>{data.type}</span>
      </div>
      <div className={classes.infosContent}>
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="calendar-alt" className="svg-inline--fa fa-calendar-alt fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z"></path></svg>
        <span>{data.time_online}</span>
      </div>
      <a href={`https://${data.domain}`} target="blank" className={classes.infosContent + ' ' + classes.infosLink}>
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="link" className="svg-inline--fa fa-link fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"></path></svg>
        <span>{data.domain}</span>
      </a>
    </div>
  </div >
};

export default ProjectDescription;