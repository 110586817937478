import React from 'react';
import HeroBanner from '../components/HeroBanner/HeroBanner';
import OffersCards from '../containers/OffersCards';
import Philosophy from '../containers/Philosophy/Philosophy';
import Partners from '../containers/Partners';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Home = () => {
  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <>
    <HeroBanner
      title='<span>OLWE</span> Groupe<br><span class="hero-banner__subtitle">Agence web</span>'
      description='Agence de développement web en Alsace, proches et à l’écoute de nos clients, nous travaillons en étroite collaboration avec vous pour répondre à vos attentes et vous suivre dans vos projets. Nous serons là pour vous accompagner dans votre transition vers le digital.'
    />
    <OffersCards />
    <Philosophy />
    <Partners />
  </>
};

export default Home;