import React from 'react';
import { ReactComponent as Instagram } from '../assets/img/instagram-icon.svg'
import { ReactComponent as LinkedIn } from '../assets/img/linkedin-icon.svg'
import { ReactComponent as Facebook } from '../assets/img/facebook-icon.svg'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Socials = () => {
  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <>
    <a href="https://www.facebook.com/OLWE-D%C3%A9veloppement-107390368265320" aria-label="Facebook" target="blank">
      <Facebook />
    </a>
    <a href="https://www.linkedin.com/company/olwe-d%C3%A9veloppement" aria-label="LinkedIn" target="blank">
      <LinkedIn />
    </a>
    <a href="https://www.instagram.com/olwe_dev" aria-label="LinkedIn" target="blank">
      <Instagram />
    </a>
  </>

};

export default Socials;



