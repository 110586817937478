import React from 'react';
import { createUseStyles } from 'react-jss';
import HeroBanner from '../components/HeroBanner/HeroBanner';
import ImageRenderer from '../components/ImageRender';
// import ServiceOthers from '../components/ServiceOthers';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ServiceAudit = () => {
  // 
  // ─── STYLE INITIALIZATION ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    wrapper: {
      margin: 20,
      '@media screen and (min-width:680px)': {
        margin: 40
      },
      '@media screen and (min-width:1024px)': {
        margin: '80px auto',
        maxWidth: 980
      }
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      '@media screen and (min-width:680px)': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 60,
        '&:nth-child(even)': {
          flexDirection: 'row-reverse',
          '&>div': {
            padding: '0 0 0 20px'
          }
        }
      }
    },
    content: {
      '@media screen and (min-width:680px)': {
        paddingRight: 20,
        width: '50%',
      }
    },
    image: {
      width: '50%'
    }
  })
  const classes = useStyle()

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <>
    <HeroBanner
      title='<span>AUDIT</span> DE SITE INTERNET'
      description='Vous souhaitez connaître les failles ou les points forts de votre site internet ou application web ? L’audit est une analyse complète permettant d’identifier les facteurs bloquants et de prendre des décisions.'
    />
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.content}>
          <h3>Performances et bonnes pratiques</h3>
          <p>L’analyse des performances et des bonnes pratiques permet de voir les potentielles failles, de vérifier la structure des pages, ainsi que les normes imposées par Google (responsive design…), dans le but d’avoir la meilleure note possible et donc d’être mieux référencé.</p>
        </div>
        <ImageRenderer classNameContainer={classes.image} url='/assets/img/services/audit/website' alt='Performances du site internet' extension='jpg' />
      </div>
      <div className={classes.container}>
        <div className={classes.content}>
          <h3>Référencement SEO</h3>
          <p>Le référencement SEO permet à votre site internet d’être bien positionné dans les moteurs de recherche tel que Google. Avoir un site internet design est bien, mais encore faut-il qu’il soit visible lors d’une recherche sur internet. C’est pourquoi le but de notre analyse est de trouver les éléments à corriger pour que le référencement naturel soit optimal.</p>
        </div>
        <ImageRenderer classNameContainer={classes.image} url='/assets/img/services/audit/seo' alt='Seo' extension='jpg' />
      </div>
      <div className={classes.container}>
        <div className={classes.content}>
          <h3>Google My Business</h3>
          <p>Notre analyse vous permettra de trouver de potentiels éléments manquants et/ou d’améliorer le contenu du Google My Business pour que les clients et les prospects aient directement toutes les informations nécessaires.</p>
        </div>
        <ImageRenderer classNameContainer={classes.image} url='/assets/img/services/audit/gmb' alt='Google my business' extension='jpg' />
      </div>
    </div>
    {/* <ServiceOthers active={['audit']} /> */}
  </>
};

export default ServiceAudit;