import React from 'react';
import { createUseStyles } from 'react-jss';
import ImageRenderer from '../components/ImageRender';
import CollaboratorsData from '../configs/collaborators.json';
import Title from '../components/Title';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Collaborators = () => {
  // 
  // ─── STYLE INITIALIZATION ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    wrapper: {
      padding: '0 20px 40px',
      '@media screen and (min-width:640px)': {
        padding: '20px 20px 60px',
      },
      '@media screen and (min-width:1024px)': {
        padding: '40px 20px 80px',
      }
    },
    container: {
      marginTop: 60,
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: 20,
      '@media screen and (min-width:640px)': {
        gap: 40
      }
    },
    description: {
      maxWidth: 800,
      margin: 'auto auto 40px auto',
      textAlign: 'center',
    },
    image: {
      width: 200
    },
    collaborator: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    name: {
      fontWeight: 'bold',
      fontSize: '1.2em'
    },
    function: {
      fontWeight: 200,
      color: 'var(--primary)'
    },
    job: {
      color: '#b9b9b9'
    }
  })
  const classes = useStyle()
  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.wrapper}>
    <div>
      <Title tag="h2" text_regular="NOTRE" text_strong="AGENCE" align="center" />
      <p className={classes.description}>Proches de nos clients, nous rechercherons toujours le meilleur pour vous, les défis ne nous font pas peur et le client sera toujours au centre de nos préoccupations. Nous sommes à votre écoute à chaque instant pour répondre à vos questionnements et à toutes vos attentes.</p>
    </div>
    <div className={classes.container}>
      {Object.entries(CollaboratorsData).map(([key, collaborator], index) => {
        return <div className={classes.collaborator}>
          <ImageRenderer classNameOuter={classes.image} key={key} url={`/assets/img/collaborators/${key}`} alt={collaborator.name} extension='png' />
          <span className={classes.name}>{collaborator.name} </span>
          {collaborator.job && <span className={classes.job}>{collaborator.function && <><span className={classes.function}>{collaborator.function} </span> - </>}{collaborator.job}</span>}
        </div>
      })}
    </div>
  </div>
};

export default Collaborators;