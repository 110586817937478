import React from 'react';
import { Helmet } from "react-helmet";
import { metaTags } from '../contexts/Contexts';
import { Outlet } from 'react-router-dom';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Head = (props) => {
  const path = props.path;
  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <>
    <Helmet>
      <title>{props.title || metaTags[path].title}</title>
      <meta name="description" content={props.description || `${metaTags[path].description}`} />
    </Helmet>
    <Outlet />
  </>
};

export default Head;