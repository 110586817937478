import { useEffect } from 'react';

let listenerCallbacks = new WeakMap();

let observer;

function handleIntersections(entries) {
  entries.forEach(entry => {
    const { target } = entry;
    if (listenerCallbacks.has(target)) {
      let cb = listenerCallbacks.get(target);

      if (entry.isIntersecting || entry.intersectionRatio) {
        observer.unobserve(target);
        listenerCallbacks.delete(target);
        cb();
      }

      if (entry.intersectionRatio >= 0.1) {
        if (target.dataset.animate) {
          target.classList.add(target.dataset.animate);
          observer.unobserve(target);
          listenerCallbacks.delete(target);
          cb();
        }
      }
    }
  });
}

function getIntersectionObserver() {
  if (observer === undefined) {
    observer = new IntersectionObserver(handleIntersections, {
      rootMargin: '0px',
      threshold: 0.1,
    });
  }
  return observer;
}

export function useIntersection(opt, callback) {
  useEffect(() => {
    let target = opt.element.current;
    let observer = getIntersectionObserver();

    listenerCallbacks.set(target, callback);
    observer.observe(target);

    return () => {
      listenerCallbacks.delete(target);
      observer.unobserve(target);
    };
  }, []);
}