import React, { useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { useIntersection } from '../contexts/IntersectionObserver';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Title = (props) => {
  const CustomTag = props.tag
  const titleRef = useRef();

  useIntersection({ element: titleRef }, () => { });

  // 
  // ─── STYLE INITIALIZATION ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      display: 'flex',
      justifyContent: props.align,
      margin: '40px 0'
    },
    title: {
      position: 'relative',
      zIndex: 1,
      margin: 0,
      fontWeight: 300,
      fontSize: 22,
      display: 'flex',
      gap: 10,
      '@media screen and (min-width:640px)': {
        fontSize: 28
      },
      '@media screen and (min-width:1024px)': {
        fontSize: 35
      }
    },
    textRegular: {
      '&:before': {
        content: "' '",
        background: "var(--primary)",
        position: 'absolute',
        bottom: 6,
        left: -10,
        width: 0,
        height: 10,
        zIndex: -1,
        transition: "0.6s"
      },
      '@media screen and (min-width:640px)': {
        '&:before': {
          bottom: 8,
          left: -13,
          width: 0,
          height: 15
        }
      },
      '@media screen and (min-width:1024px)': {
        '&:before': {
          left: -20,
          width: 0,
          height: 20
        }
      }
    },
    textStrong: {
      fontWeight: 900
    }
  })
  const classes = useStyle()


  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div ref={titleRef} className={classes.container} data-animate='animate-title'>
    <CustomTag className={classes.title}>
      <span className={classes.textRegular}>{props.text_regular}</span>
      <strong className={classes.textStrong}>{props.text_strong}</strong>
    </CustomTag>
  </div>
};

export default Title;