import React from 'react';
import { createUseStyles } from 'react-jss';
import ProjectCard from '../components/ProjectCard';
import HeroBanner from '../components/HeroBanner/HeroBanner';
import Title from '../components/Title';
import ProjectData from '../configs/projects.json';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Projects = () => {
  // 
  // ─── STYLE INITIALIZATION ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    projectsContainer: {
      marginTop: 20,
      padding: 20,
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(250px,1fr))',
      gap: 8,
      '@media screen and (min-width:1024px)': {
        // maxWidth: 'calc((350px + 20px) * 4)',
        margin: '40px auto',
        gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))'
      }
    },
    description: {
      textAlign: 'center',
      '@media screen and (min-width:850px)': {
        maxWidth: 850,
        margin: 'auto'
      }
    }
  })
  const classes = useStyle()

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <>
    <HeroBanner
      title='<span>Création</span> de site internet'
    />
    <Title tag="h2" text_regular="NOS" text_strong="RÉALISATIONS" align="center" />
    <p className={classes.description}>Colorés, créatifs, professionnels ou design, nous créons des sites internet pour tous vos projets et dans tous types d’activités. Découvrez nos réalisations et nos créations :</p>
    <div className={classes.projectsContainer}>
      {ProjectData.map((value) => {
        return <ProjectCard key={value.username} data={value} />
      })}
    </div>
  </>
};

export default Projects;