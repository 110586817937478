import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import "./Navigation.scss";
import { ReactComponent as Logo } from '../../assets/img/logo.svg'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Navigation = () => {

  //
  // ─── HANDLE MENU DISPLAY ───────────────────────────────────────
  //
  const toggleMenu = () => {
    document.querySelector('header').classList.toggle('headerToggle')
    document.querySelector('nav').classList.toggle('navToggle');
  }

  const toggleSubMenu = (obj) => {
    document.querySelector('.submenu-parent').classList.toggle('submenu-toggle')
    document.querySelector('.menu-subitems').classList.toggle('menu-subitems-active');
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <><header>
    <Link to="/" aria-label="Accueil" className="menu-logo">
      <Logo />
    </Link>
    <div className="burger" onClick={toggleMenu}>
      <span></span>
      <span></span>
    </div>
  </header>
    <nav>
      <div className="burger" onClick={toggleMenu}>
        <span></span>
        <span></span>
      </div>
      <div className="menu-container">
        <ul>
          <li onClick={toggleMenu} style={{ transitionDelay: "0.3s" }}>
            <NavLink to="/" className={(navData) => (navData.isActive ? 'menu-active' : '')}>Accueil</NavLink>
          </li>
          <li onClick={toggleMenu} style={{ transitionDelay: "0.36s" }}>
            <NavLink to="/agence" className={(navData) => (navData.isActive ? 'menu-active' : '')}>Agence</NavLink>
          </li>
          <li className="" style={{ transitionDelay: "0.42s" }}>
            <div onClick={toggleSubMenu} className='submenu-parent'>
              Services
              <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="angle-down" className="svg-inline--fa fa-angle-down fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z"></path></svg>
            </div>
          </li>
          <li className="menu-subitems">
            <ul>
              <li onClick={toggleMenu}><NavLink to="/services/web" className={(navData) => (navData.isActive ? 'menu-active' : '')}>Web</NavLink></li>
              {/* <li onClick={toggleMenu}><NavLink to="/services/communication" className={(navData) => (navData.isActive ? 'menu-active' : '')}>Communication</NavLink></li> */}
              <li onClick={toggleMenu}><NavLink to="/services/audit" className={(navData) => (navData.isActive ? 'menu-active' : '')}>Audit</NavLink></li>
            </ul>
          </li>
          <li onClick={toggleMenu} style={{ transitionDelay: "0.48s" }}>
            <NavLink to="/nos-realisations" className={(navData) => (navData.isActive ? 'menu-active' : '')}>Projets</NavLink>
          </li>
          <li onClick={toggleMenu} style={{ transitionDelay: "0.54s" }}>
            <NavLink to="/contact" className={(navData) => (navData.isActive ? 'menu-active' : '')}>Contact</NavLink>
          </li>
        </ul>
      </div>
      <div className="actu-container">
      </div>
    </nav>
  </>
};

export default Navigation;