import React from 'react';
import { createUseStyles } from 'react-jss';
import ImageRenderer from '../components/ImageRender';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const OffersCards = () => {
  // 
  // ─── STYLE INITIALIZATION ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      '&> div:nth-child(1)': {
        background: '#FFE5AA'
      },
      '& div:nth-child(2)': {
        background: '#FFCB55'
      },
      '@media screen and (min-width:850px)': {
        flexDirection: 'row',
        flexWrap: 'wrap',
        '&>div': {
          width: 'calc(50% - 40px)'
        }
      }
    },
    content: {
      padding: '40px 20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '@media screen and (min-width:1024px)': {
        padding: '80px 20px'
      }
    },
    title: {
      margin: '40px 20px 20px',
      textAlign: 'center'
    },
    description: {
      maxWidth: 450,
      margin: 0,
      textAlign: 'center',
      '@media screen and (min-width:1024px)': {
        maxWidth: 350
      }
    },
    button: {
      marginTop: 40
    }
  })
  const classes = useStyle()

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.container}>
    <div className={classes.content}>
      <ImageRenderer url='/assets/img/offer1.svg' alt='Offre web' simple_image={true} />
      <h2 className={classes.title}>WEB</h2>
      <p className={classes.description}>Nous vous accompagnons dans toutes les étapes du développement de votre projet. Découvrez nos missions et nos conseils de développement sur-mesure.</p>
      <a href="/services/web" className={classes.button + " button"}>EN SAVOIR PLUS</a>
    </div>
    <div className={classes.content}>
      <ImageRenderer url='/assets/img/offer3.svg' alt='Offre audit' simple_image={true} />
      <h2 className={classes.title}>AUDIT</h2>
      <p className={classes.description}>Faire un état des lieux de sa stratégie digitale est quelque chose de bénéfique, l’audit permet d'améliorer la visibilité de votre entreprise.</p>
      <a href="/services/audit" className={classes.button + " button"}>EN SAVOIR PLUS</a>
    </div>
  </div>
};

export default OffersCards;