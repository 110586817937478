import React from 'react';
import { createUseStyles } from 'react-jss';
import ImageRenderer from '../components/ImageRender';
import Title from '../components/Title';
import Clients from '../configs/clients.json';
import Projects from '../configs/projects.json';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Partners = () => {
  // 
  // ─── STYLE INITIALIZATION ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      padding: 20,
      '@media screen and (min-width:1024px)': {
        padding: 60
      }
    },
    references: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      '@media screen and (min-width:1024px)': {
        maxWidth: 1400,
        margin: 'auto'
      }
    },
    link: {
      position: 'relative',
      margin: 20,
      '&:before': {
        content: "' '",
        width: 0,
        height: 30,
        background: 'rgba(255, 177, 0, 0.6)',
        position: 'absolute',
        top: '50%',
        left: 0,
        transform: 'translateY(-50%)',
        zIndex: 1,
        transition: '0.4s',
      },
      '&:hover:before': {
        content: "' '",
        width: '100%',
      }
    },
    image: {
      maxHeight: 60,
      maxWidth: 200,
      filter: 'brightness(40%) grayscale(100%)',
      '@media screen and (min-width:1024px)': {
        margin: 20
      },
      '& img': {
        maxHeight: 60,
      }
    }
  })
  const classes = useStyle()

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.container}>
    <Title tag="h2" text_regular="ILS NOUS FONT" text_strong="CONFIANCE" align="center" />
    <div className={classes.references}>
      {Clients.map((client) => {
        const clientProject = Projects.filter(project => project.username === client.username)
        return <a href={`/nos-realisations/${clientProject[0].domain}`} className={classes.link}>
          <ImageRenderer classNameOuter={classes.image} key={client.username} url={client.logo} alt={client.name} extension='png' />
        </a>
      })}
    </div>
  </div>
};

export default Partners;