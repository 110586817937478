import React from 'react';
import { useParams } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import HeroBanner from '../components/HeroBanner/HeroBanner';
import ProjectData from '../configs/projects.json';
import ProjectDescription from '../containers/ProjectDescription';
import ProjectCard from '../components/ProjectCard';
import ContactForm from '../containers/ContactForm/ContactForm';
import Head from '../components/Head';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ProjectDetail = () => {
  const projectParams = useParams()
  let data = ProjectData.filter(v => v.domain === projectParams.domain)[0];
  let otherData = ProjectData.filter(v => v.domain !== projectParams.domain);
  let projectLength = otherData.length - 1;
  let projectShow = [];

  for (let i = 0; i < 3; i++) {
    let number = Math.floor(Math.random() * (projectLength - 0 + 1)) + 0;
    projectShow.push(otherData[number]);
    otherData.splice(number, 1);
    projectLength = otherData.length - 1;
  }

  // 
  // ─── STYLE INITIALIZATION ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    otherProjects: {
      display: 'flex',
      '& a:nth-child(1)': {
        display: 'none',
      },
      '& > a': {
        width: '50%'
      },
      '@media screen and (min-width:640px)': {
        '& a:nth-child(1)': {
          display: 'block',
        },
      },
      '@media screen and (min-width:1200px)': {
        '& > a': {
          height: 300
        }
      }
    }
  })
  const classes = useStyle()

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div>
    <Head title={`${data.name} | Nos réalisations`} description='Colorés, créatifs, professionnels ou design, nous créons des sites internet pour tous vos projets et dans tous types d’activités.' />
    <HeroBanner title={data.name} small={true} disable_particles={true} />
    <ProjectDescription data={data} />
    <div className={classes.otherProjects}>
      {projectShow.map((value) => {
        return <ProjectCard key={value.username} data={value} />
      })}
    </div>
    <ContactForm />
  </div>
};

export default ProjectDetail;