import React from 'react';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const NotFound = () => {
  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <h1>Not Found</h1>
};

export default NotFound;