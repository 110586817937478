import React, { useState, useRef } from 'react';
import { useIntersection } from '../contexts/IntersectionObserver';

const ImageRenderer = (props) => {
  const [isInView, setIsInView] = useState(false);
  const imgRef = useRef();

  useIntersection({ element: imgRef }, () => {
    setIsInView(true);
  });

  let renderImage = <picture className={props.classNameOuter + ' image'}>
    <source srcSet={`${props.url}${props.extension ? '.webp' : ''}`} type='image/webp' />
    <source srcSet={`${props.url}${props.extension ? '.' + props.extension : ''}`} type={`image/${props.extension}`} />
    <img alt={props.alt} src={`${props.url}${props.extension ? '.' + props.extension : ''}`} />
  </picture>

  if (props.simple_image) {
    renderImage = <img
      className={props.classNameOuter + ' image'}
      src={props.url}
      alt={props.alt}
    />
  }

  return <div ref={imgRef} className={props.classNameContainer + ' image-container'}>
    {isInView && renderImage}
  </div>

};

export default ImageRenderer;