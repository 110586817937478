import React from 'react';
import Socials from '../../components/Socials';
import { ReactComponent as Logo } from '../../assets/img/logo-white.svg'
import "./Footer.scss";

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Footer = () => {
  let date = new Date();
  let year = date.getFullYear();

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <footer className="footer">
    <div className="footer__logo-container">
      <Logo />
    </div>
    <div className="footer__container">
      <div>
        <p className="footer__presentation">Agence de développement web en Alsace, proches et à l’écoute de nos clients, nous travaillons en étroite collaboration avec vous pour répondre à vos attentes et vous suivre dans vos projets. Nous serons là pour vous accompagner dans votre transition vers le digital.</p>
      </div>
      <div>
        <span className="footer__title">Réseaux sociaux</span>
        <div className="footer__social">
          <Socials />
        </div>
      </div>
      <div>
        <span className="footer__title">Services</span>
        <ul>
          <li><a href="services/web">Web</a></li>
          <li><a href="services/audit">Audit</a></li>
        </ul>
      </div>
      <div>
        <span className="footer__title">Informations</span>
        <ul>
          <li><a href="/mentions-legales">Mentions légales</a></li>
          <li><a href="/politique-de-confidentialite">Politique de confidentialité et cookies</a></li>
          {/* <li className="footer__cookie-modal" onclick="toggleModal({ className: '.cookie-modal-wrapper', display: 'block' })">Gestion des cookies</li> */}
          <li>{`OLWE Groupe © ${year}`}</li>
        </ul>
      </div>
    </div >
  </footer >
};

export default Footer;