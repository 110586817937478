// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React from "react";
import { Route, Routes } from 'react-router-dom';
import Home from '../views/Home';
import Agency from '../views/Agency';
import Projects from '../views/Projects';
import NotFound from '../views/NotFound';
import ProjectDetail from "../views/ProjectDetail";
import ContactForm from "../containers/ContactForm/ContactForm";
import ServiceWeb from "../views/ServiceWeb";
import ServiceCommunication from "../views/ServiceCommunication";
import ServiceAudit from "../views/ServiceAudit";
import Head from "../components/Head";
import Legals from "../views/Legals";
import Privacy from "../views/Privacy";

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Router = (props) => {

  const routes = [
    { path: '/', component: <Home /> },
    { path: '/agence', component: <Agency /> },
    { path: '/nos-realisations', component: <Projects /> },
    { path: '/nos-realisations/:domain', component: <ProjectDetail />, disable_helmet: true },
    { path: '/services/web', component: <ServiceWeb /> },
    // { path: '/services/communication', component: <ServiceCommunication /> },
    { path: '/services/audit', component: <ServiceAudit /> },
    { path: '/contact', component: <ContactForm /> },
    { path: '/mentions-legales', component: <Legals /> },
    { path: '/politique-de-confidentialite', component: <Privacy /> },
  ]

  // 
  // ─── DECLARATION OF ALL NECESSARY ROUTES ───────────────────────────────────────
  //
  return <>
    <Routes>
      {routes.map((route) => {
        if (route.disable_helmet) {
          return <Route key={route.path} path={route.path} element={route.component} />
        } else {
          return <Route element={<Head path={route.path} />}>
            <Route key={route.path} path={route.path} element={route.component} />
          </Route>
        }
      })}
      <Route path="*" element={<NotFound />} />
    </Routes>
  </>
};

export default Router