import Title from '../components/Title';
import { createUseStyles } from 'react-jss';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Privacy = () => {
  // 
  // ─── STYLE INITIALIZATION ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      padding: 20
    },
    content: {
      '@media screen and (min-width:1000px)': {
        padding: '30px 60px',
        margin: 'auto',
      }
    },
    links: {
      color: 'var(--primary)'
    }
  })
  const classes = useStyle()

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.container}>
    <div className={classes.content}>
      <Title tag="h2" text_regular="NOS AUTRES" text_strong="SERVICES" align="flex-start" />

      <h1>DONNEES PERSONNELLES</h1>
      <h2>POLITIQUE DE PROTECTION DE DONNEES A CARACTERE PERSONNEL</h2>
      <p><strong>OLWE Groupe</strong> s’engage à protéger les informations personnelles et la vie privée des visiteurs du site internet et de toute personne utilisant ses Services de manière générale.</p>
      <p>La présente Politique de protection des données personnelles et cookies a pour objectif de vous informer :</p>
      <ul>
        <li>Des types de données personnelles que nous collectons vous concernant</li>
        <li>Sur la manière dont vos données à caractère personnel sont traitées par <strong>OLWE Groupe</strong> et ses éventuels partenaires et sous-traitants</li>
        <li>Des conditions et modalités d’utilisation de vos données personnelles et de vos droits à cet égard, dans le respect de la législation européenne et française applicables</li>
        <li>Toutes les opérations sur vos données personnelles sont réalisées dans le respect des réglementations en vigueur et notamment du Règlement n°2016/679 du Parlement européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données (ci-après le « RGPD »), et de toute législation nationale applicable à la protection des données.</li>
      </ul>
      <h3>1. DEFINITIONS</h3>
      <p><strong>Donnée à caractère personnel</strong> - toute information relative à une personne physique identifiée ou qui peut être identifiée, directement ou indirectement, par référence à un numéro d'identification ou à un ou plusieurs éléments qui lui sont propres.</p>
      <p><strong>Fichier</strong> - tout ensemble structuré et stable de données à caractère personnel accessibles selon des critères déterminés.</p>
      <p><strong>Personne concernée</strong> personne physique à laquelle se rapportent les données qui font l’objet du traitement ;</p>
      <p><strong>Responsable du traitement</strong> - l’entité qui collecte et traite des données à caractère personnel ;</p>
      <p><strong>Traitement de données</strong> - toute opération ou tout ensemble d’opérations portant sur de telles données, quel que soit le procédé utilisé, et notamment la collecte, l’enregistrement, l’organisation, la conservation, l’adaptation ou la modification, l’extraction, la consultation, l’utilisation, la communication par transmission, diffusion ou toute autre forme de mise à disposition, le rapprochement ou l’interconnexion, ainsi que le verrouillage, l’effacement ou la destruction ;</p>
      <p><strong>Destinataire</strong> - toute personne habilitée à recevoir communication des données autres que le Responsable de traitement, le Sous-traitant, et les personnes qui, en raison de leurs fonctions, sont chargées de traiter les données ;</p>
      <p><strong>Tiers autorisés</strong> - les autorités légalement habilitées dans le cadre d’une mission particulière ou de l’exercice d’un droit de communication, à demander au Responsable de traitement de leur communiquer des données personnelles ;</p>
      <h3>2. IDENTITE DU RESPONSABLE DE TRAITEMENTS</h3>
      <p>OLWE Groupe</p>
      <h3>3. TRAITEMENT DE DONNEES A CARACTERE PERSONNEL</h3>
      <p>Dans le cadre de l’exploitation du site <a href="https://olwe.fr" className={classes.links}>olwe.fr</a>, <strong>OLWE Groupe</strong> collecte des données vous concernant. Ces données sont traitées conformément aux finalités prévues lors de la collecte, certaines informations étant obligatoires et d'autres facultatives, comme indiqué dans les formulaires de collecte.</p>
      <p>Par ailleurs, <strong>OLWE Groupe</strong> pourra être amené à collecter des données à caractère personnel pour d’autres finalités, en prenant soin de recueillir le consentement préalable des personnes concernées.</p>
      <p><strong>Les traitements mis en place sur notre site ont pour finalités de permettre :</strong></p>
      <ul>
        <li>De nous contacter par formulaire mis à votre disposition;</li>
        <li>L’élaboration de statistiques relatives à la fréquentation du site;</li>
        <li>La gestion des inscriptions aux newsletters;</li>
        <li>La gestion de la possibilité de partager du contenu sur les réseaux sociaux Twitter, Facebook.</li>
      </ul>
      <p>La collecte des données repose sur votre consentement que vous manifestez en acceptant le dépôt des cookies ou en cochant la case associée sur le formulaire de collecte ou en nous écrivant directement.</p>
      <p>Le traitement peut reposer sur la base de l’intérêt légitime de <strong>OLWE Groupe</strong>, notamment pour le bon fonctionnement du site web.</p>
      <p>Lorsque vous réalisez un achat sur le site, le traitement de données est nécessaire à la gestion de votre commande et donc à l’exécution du contrat entre vous et <strong>OLWE Groupe</strong>.</p>
      <p><strong>OLWE Groupe est susceptible de collecter auprès de vous les données suivantes :</strong></p>
      <p style={{ textDecoration: 'underline' }}>Formulaire de contact</p>
      <p><strong>Données d’identification : </strong> nom, prénom, adresse mail</p>
      <p><strong>Données de contact : </strong>objet des messages, les correspondances échangées, date et heure des messages</p>
      <p style={{ textDecoration: 'underline' }}>Statistiques : Cookies et autres traceurs</p>
      <p><strong>Données de connexion : </strong>pages consultées par l’utilisateur, la date et l’heure de la consultation, lieu géographique, provenance de la connexion (réseaux sociaux, moteur de recherche, site partenaire, annuaire).</p>
      <h3>4. DUREES DE CONSERVATION</h3>
      <p>OLWE Groupe conservera vos données personnelles dans un environnement sécurisé uniquement pendant la durée nécessaire à la réalisation des finalités pour lesquelles elles ont été collectées ou pendant la durée de conservation maximale prévue par la législation applicable en France.</p>
      <ul>
        <li><strong>Gestion des demandes via le formulaire de contact/chatbot : </strong>1 an à compter de la réception des demandes</li>
        <li><strong>Newsletters : </strong>La durée de votre inscription à la newsletter jusqu’à votre désinscription.</li>
        <li><strong>Cookies statistiques : </strong>6 mois</li>
      </ul>
      <h3>5. DROITS DES PERSONNES</h3>
      <p>Conformément au Règlement (UE) 2016/679 relatif à la protection des données à caractère personnel, vous disposez du droit de demander au responsable du traitement l'accès aux données à caractère personnel vous concernant, la rectification ou l'effacement de celles-ci, la limitation du traitement de vos données personnelles.</p>
      <p>Vous disposez également d’un droit d’opposition au traitement de vos données personnelles pour des motifs légitimes, ainsi que d’un droit d’opposition à ce que vos données soient utilisées à des fins de prospection commerciale.</p>
      <p>Enfin, vous avez le droit de définir des directives générales et particulières définissant la manière dont vous entendez que soient exercés, après votre décès, ces droits.</p>
      <p>Si vous estimez, après nous avoir contactés, que vos droits sur vos données personnelles ne sont pas respectés, vous pouvez adresser une réclamation à la CNIL ou à l’Autorité de contrôle de votre lieu de résidence.</p>
      <p>Pour exercer ces droits ou pour toute question sur le traitement de vos données dans ce dispositif, vous pouvez contacter notre Délégué à la protection des données (DPO), en justifiant votre identité par référence à votre numéro client ou à tout élément permettant d’identifier votre identité, sinon veuillez joindre une photocopie de votre pièce d’identité. </p>
      <p>Pour exercer vos droits, vous pouvez vous adresser à <strong>OLWE Groupe</strong></p>
      <p>Par voie électronique : <a className={classes.links} href='mailto:contact@olwe.fr'>contact@olwe.fr</a></p>
      <h3>6. DESTINATAIRES</h3>
      <p>Les destinataires de vos données à caractère personnel sont les services concernés de <strong>OLWE Groupe</strong> et de ses sous-traitants dans le cadre de leurs missions.</p>
      <p>Ces données personnelles ne sont pas communiquées à des tiers. Cependant, <strong>OLWE Groupe</strong> se réserve le droit de transmettre les données à caractère personnel des tiers autorisés afin de satisfaire à ses obligations légales, et notamment si elle en était contrainte par réquisition judiciaire. </p>
      <p>Pour certains services, comme le partage sur les réseaux sociaux, les cookies, nos partenaires ont accès à vos données.</p>
      <p><strong>OLWE Groupe</strong> exige à tous ses prestataires et partenaires de traiter les données personnelles conformément aux engagements en matière de confidentialité et de sécurité.</p>
      <h3>7. SECURITE</h3>
      <p>Compte tenu de l’évolution des technologies, des coûts de mise en œuvre, de la nature des données à protéger ainsi que des risques pour les droits et libertés des personnes, <strong>OLWE Groupe</strong> met en œuvre toutes les mesures techniques et organisationnelles appropriées afin de garantir la confidentialité des données à caractère personnel collectées et traitées et pour assurer un niveau de sécurité adapté au risque.</p>
      <p><strong>OLWE Groupe</strong> assure la sécurité de vos données à caractère personnel en mettant en place une protection des données renforcée par l’utilisation de moyens de sécurisation physiques et logiques.</p>
      <p><strong>OLWE Groupe</strong> a défini une politique de protection des données personnelles et informe toute personne agissant sous son autorité qui accède à ces informations de leur caractère confidentiel. En outre, <strong>OLWE Groupe</strong> veille à ce que ses prestataires techniques susceptibles d’avoir connaissance des données personnelles, respectent la même confidentialité.</p>
      <p>Toutefois, il appartient à chaque utilisateur de veiller à la sécurité des transmissions des données personnelles par internet. <strong>OLWE Groupe</strong> ne saurait être tenu responsable des transmissions des données personnelles par internet.</p>
      <h3>8. TRANSFERT DE DONNEES A CARACTERE PERSONNEL</h3>
      <p>Vos données pourront être transférées dans un pays tiers à l’espace économique européen.</p>
      <p><strong>OLWE Groupe</strong> a mis en place les garanties suivantes pour s’assurer d’un niveau de protection suffisant de vos informations :</p>
      <ul>
        <li>Le pays du ou des destinataires offre un niveau de protection adéquat par décision de la Commission européenne : France ;</li>
        <li>Le transfert est encadré, conformément aux exigences du Règlement européen n°2016/679/UE du 27 avril 2016, par (choisir parmi les possibilités suivantes) :</li>
        <ul>
          <li>des clauses types de protection adoptées par la Commission européenne;</li>
          <li>des clauses types de protection adoptées par la CNIL;</li>
          <li>des règles d’entreprises contraignantes validées par la CNIL ou un code de conduite approuvé par la CNIL;</li>
          <li>une certification délivrée par un organisme de certification agrée.</li>
        </ul>
      </ul>
      <h3>9. LES BOUTONS DES RESEAUX SOCIAUX</h3>
      <p>Vous avez la possibilité de partager les pages qui vous intéressent sur les réseaux sociaux en cliquant sur des boutons de partage.</p>
      <p>La collecte d’information par les boutons de partage des réseaux sociaux est soumise aux politiques de confidentialité de ces tiers. Nous vous invitons à lire les politiques de ces réseaux sociaux pour connaitre l’utilisation qui est faite des données personnelles vous concernant.</p>
      <h3>10. COOKIES</h3>
      <p><strong>Définition</strong></p>
      <p>Les cookies sont des données stockées dans l’équipement terminal d’un internaute et utilisées par le site pour envoyer des informations au navigateur de l’internaute, et permettant à ce navigateur de renvoyer des informations au site d’origine (par exemple un identifiant de session, le choix d’une langue ou une date).</p>
      <p>Les cookies permettent de conserver, pendant la durée de validité du cookie concerné, des informations d’état lorsqu’un navigateur accède aux différentes pages d’un site web ou lorsque ce navigateur retourne ultérieurement sur ce site web.</p>
      <p>Seul l’émetteur d’un cookie peut lire ou modifier les informations qui y sont contenues.</p>
      <p>Plusieurs types de cookies sont susceptibles d’être utilisés par le site :</p>
      <ul>
        <li>Les cookies strictement nécessaires à la navigation sur le site et devant garantir son bon fonctionnement;</li>
        <li>Les cookies qui permettent d’établir des statistiques de fréquentations;</li>
        <li>Les cookies et plug-in de réseaux sociaux;</li>
      </ul>
      <p>Les contenus publicitaires diffusés sur notre site Internet sont susceptibles de contenir des cookies émis par des tiers. De tels cookies peuvent également être déposés sur votre terminal à l’occasion de votre navigation. L'émission et l'utilisation de ces cookies par des tiers sont soumises aux politiques de protection de la vie privée de ces tiers.</p>
      <p>Cookies et traceurs tiers :</p>
      <ul>
        <li><a className={classes.links} target='blank' href='https://policies.google.com/privacy'>Google Analytics</a></li>
        <li><a className={classes.links} target='blank' href='https://www.facebook.com/policies/cookies/'>Pixel Facebook</a></li>
      </ul>
      <p><strong>Consentement</strong></p>
      <p>Lorsque vous naviguez sur notre site web, le dépôt et la lecture de cookies permettent d’analyser votre navigation et de mesurer l’audience du site internet <a href="https://olwe.fr">olwe.fr</a>. Vous avez le choix d’autoriser ou non le dépôt des cookies utilisés par notre site et non indispensables à son fonctionnement. Ils ne seront déposés que si vous accepter leur utilisation. Pour paramétrer ou modifier à tout moment votre choix vous pouvez utiliser notre outil de gestion des cookies.</p>
      <p>En paramétrant votre navigateur pour refuser les cookies, certaines fonctionnalités, pages, espaces du site ne seront pas accessibles, ce dont nous ne saurions être responsables.</p>
      <p>Nous attirons également votre attention sur le fait que, lorsque vous vous opposez à l’installation ou à l’utilisation d’un cookie, un cookie de refus est installé sur votre équipement terminal.</p>
      <p>Si vous supprimez ce cookie de refus, il ne sera plus possible de vous identifier comme ayant refusé l’utilisation de cookies.</p>
      <p>De même, lorsque vous consentez à l’installation de cookies, un cookie de consentement est installé. Les cookies de consentement ou de refus doivent rester sur votre équipement terminal.</p>
      <h3>11. MISES A JOUR</h3>
      <p>La présente politique entre en vigueur le 15 mars 2021</p>
      <p>Pour vérifier les mises à jour de notre Politique, il convient de consulter régulièrement cette page.</p>
      <h3>VOUS AVEZ DES QUESTIONS ?</h3>
      <p>Le DPO de <strong>OLWE Groupe</strong> se tient à votre disposition pour tout renseignement complémentaire quant à l’utilisation de données vous concernant.</p>
      <p>Merci de lui envoyer votre demande par mail : <a className={classes.links} href="mailto:contact@olwe.fr">contact@olwe.fr</a></p>
    </div>
  </div>
};

export default Privacy;