import React, { useEffect } from 'react';
import ReactGA from "react-ga4";
import Router from "./contexts/Router";
import './assets/css/main.scss';
import Navigation from './containers/Navigation/Navigation';
import Footer from './containers/Footer/Footer';
import { useLocation } from "react-router-dom";

const App = () => {
  ReactGA.initialize("G-9J0VRMJ3VN");
  ReactGA.send("pageview");

  // 
  // ─── REMOVE ───────────────────────────────────────
  //
  const location = useLocation();
  useEffect(() => {
    const checkParticles = document.querySelector('.particleScript');
    if (checkParticles) {
      checkParticles.remove()
    }
  }, [location])

  return <div>
    <div>
      <Navigation />
      <main>
        <Router />
      </main>
      <Footer />
    </div>
  </div>
}

export default App;