export const metaTags = {
  "/": {
    title: "OLWE Groupe | Agence web",
    description: "Agence de développement web en Alsace, Olwe répond à vos attentes et vous suit dans tous vos projets pour vous accompagner dans votre transition vers le digital."
  },
  "/agence": {
    title: "Notre agence | OLWE Groupe",
    description: "Proches de nos clients, nous rechercherons toujours le meilleur pour vous, les défis ne nous font pas peur et le client sera toujours au centre de nos préoccupations."
  },
  "/nos-realisations": {
    title: "Nos réalisations | OLWE Groupe",
    description: "Colorés, créatifs, professionnels ou design, nous créons des sites internet pour tous vos projets et dans tous types d’activités."
  },
  "/services/web": {
    title: "Web | Nos services",
    description: "Vous souhaitez vous lancer dans un projet qui vous ressemble ? Nous sommes là pour vous accompagner de A à Z dans la conception de vos projets."
  },
  "/services/communication": {
    title: "Communication | Nos services",
    description: "Les différents axes de la communication sont essentiels pour avoir de la visibilité sur internet ainsi qu’une image de marque qui vous correspond."
  },
  "/services/audit": {
    title: "Audit | Nos services",
    description: "Vous souhaitez connaître les failles ou les points forts de votre site internet ou application web ? L’audit est une analyse complète de votre site internet."
  },
  "/contact": {
    title: "Nous contacter | OLWE Groupe",
    description: "Perfectionnistes, à l’écoute et proches de vous, nous sommes là pour répondre à toutes vos questions."
  },
  '/mentions-legales': {
    title: "Mentions légales",
    description: ""
  },
  '/politique-de-confidentialite': {
    title: "Politique de confidentialite",
    description: ""
  }
}