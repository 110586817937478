import React from 'react';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import ImageRenderer from './ImageRender';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ProjectCard = (props) => {
  let data = props.data;

  // 
  // ─── STYLE INITIALIZATION ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      height: 200,
      position: 'relative',
      overflow: 'hidden',
      '&:hover': {
        '& .project-background img': {
          transform: 'scale(1.2)'
        }
      },
      '@media screen and (min-width:240px)': {
        height: 240
      }
    },
    imageContainer: {
      height: '100%'
    },
    image: {
      height: '100%',
      '& img': {
        transition: '0.4s'
      }
    },
    logo: {
      '& img': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        maxWidth: '80%',
        maxHeight: '60%',
        width: 'initial',
        height: 'initial',
        objectFit: 'initial',
      }
    },
    color: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      opacity: 0.55,
      background: data.color
    }
  })
  const classes = useStyle()

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <a href={`/nos-realisations/${data.domain}`} className={classes.container}>
    <ImageRenderer classNameOuter={classes.image} classNameContainer={classes.imageContainer + ' project-background'} url={`/assets/img/projects/${data.username}`} alt={""} extension='jpg' />
    <span className={classes.color}></span>
    <ImageRenderer classNameContainer={classes.logo} url={`/assets/img/clients_logo/${data.username}`} alt={""} extension='png' />
  </a>
};

export default ProjectCard;