import React from 'react';
import Title from '../../components/Title';
import './ContactForm.scss';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ContactForm = (props) => {
  let theme = props.theme
  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={`${theme === 'dark' ? 'contact-wrapper-dark' : ''} contact-wrapper`}>
    <div className="contact-container">
      <Title tag="h2" text_regular="NOUS" text_strong="CONTACTER" align="left" />
      <p>Perfectionnistes, à l’écoute et proches de vous, nous sommes là pour répondre à toutes vos questions. Peu importe le challenge, nous trouverons la meilleure solution pour vous !</p>
    </div>
    <div className="contact-formular">
      <form onsubmit="return false" className="contact-form">
        <div className="formular-input-wrapper">
          <div>
            <label for="firstname">Prénom</label>
            <input id="firstname" type="text" name="firstname" maxlength="150" />
          </div>
          <div style={{ marginLeft: 20 }}>
            <label for="name">Nom</label>
            <input id="name" type="text" name="name" maxlength="300" />
          </div>
        </div>
        <label for="email">Email</label>
        <input id="email" type="email" name="email" />
        <label for="object">Sujet</label>
        <input id="object" type="text" name="object" />
        <label for="services">Nos services</label>
        <select name="services" id="services">
          <option>Web</option>
          <option>Communication</option>
          <option>Audit</option>
        </select>
        <label for="description">Expliquez-nous votre demande</label>
        <textarea id="description" name="description" maxlength="700"></textarea>
        <input type="hidden" id="g-recaptcha-response" name="g-recaptcha-response" />
        <small style={{ marginBottom: 20, fontSize: 13 }}>Ce site est protégé par reCAPTCHA et Google.
          <a target="blank" href="https://policies.google.com/privacy" style={{ color: theme === 'dark' ? '#FFF' : '#000' }}>La politique de confidentialité</a> et
          <a target="blank" href="https://policies.google.com/terms" style={{ color: theme === 'dark' ? '#FFF' : '#000' }}> les conditions d'utilisation</a> s'appliquent.
        </small>
        <p className="form-result"></p>
        <button onclick="formular();" className={`button ${theme === 'dark' ? 'button-color' : ''}`}>
          Envoyer
        </button>
      </form>
    </div >
  </div >
};

export default ContactForm;