import React from 'react';
import { Link } from 'react-router-dom';
import './HeroBanner.scss'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const HeroBanner = (props) => {

  const checkParticleScript = document.querySelector('.particleScript');
  if (!checkParticleScript && !props.disable_particles) {
    let particleScriptContainer = document.createElement('div');
    particleScriptContainer.setAttribute('class', 'particleScript');
    document.body.appendChild(particleScriptContainer);

    let js = document.createElement('script');
    js.src = '/assets/js/particle_olwe.js';
    particleScriptContainer.appendChild(js);
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={`home-container ${props.small ? 'home-container-small' : ''}`}>
    <div className="home-slider">
      <div className="home-title">
        <h1 dangerouslySetInnerHTML={{ __html: props.title }} />
      </div>
      <p>{props.description}</p>
    </div>
    {!props.remove_contact && <div className="home-contact">
      <Link to="/contact">Nous contacter</Link>
    </div>}
    <div className="home-container-anim"></div>
  </div >
};

export default HeroBanner;

