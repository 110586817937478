import React, { useRef } from 'react';
import Title from '../../components/Title';
import "./Philosophy.scss"
import { useIntersection } from '../../contexts/IntersectionObserver';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Philosophy = () => {
  const philosopyRef = useRef();
  useIntersection({ element: philosopyRef }, () => { });

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className='philosophy-container'>
    <Title tag="h2" text_regular="NOTRE" text_strong="PHILOSOPHIE" align="center" />
    <p>La philosophie de l’agence est très simple : L’HUMAIN. Nous mettons au centre de notre expertise les femmes et les hommes qui souhaitent se lancer dans un projet web. Nous appliquons 4 concepts à chaque personne qui prend contact avec nous :</p>
    <div ref={philosopyRef} class="philosophy-timeline" data-animate='animate-philosophy'>
      <span class="philosophy-line"></span>
      <div class="philosophy-timeline-container">
        <div class="philosophy-timeline-title">
          <span>1</span>
          <h3>ÉCHANGER</h3>
        </div>
        <p>Le premier contact est primordial et permet d’échanger ensemble à propos de vos idées de projet, le tout pour établir une relation de confiance mutuelle.</p>
      </div>
      <div class="philosophy-timeline-container">
        <div class="philosophy-timeline-title">
          <span>2</span>
          <h3>COMPRENDRE</h3>
        </div>
        <p>Nous analysons toutes vos attentes afin de pouvoir vous accompagner au mieux dans la réalisation de vos projets.</p>
      </div>
      <div class="philosophy-timeline-container">
        <div class="philosophy-timeline-title">
          <span>3</span>
          <h3>PROPOSER </h3>
        </div>
        <p> Nous vous proposons les meilleures solutions pour répondre à toutes vos envies et vos attentes. Être à votre écoute est notre priorité pour avancer ensemble.</p>
      </div>
      <div class="philosophy-timeline-container">
        <div class="philosophy-timeline-title">
          <span>4</span>
          <h3>SUIVRE </h3>
        </div>
        <p>Un projet ne s’arrête pas à sa mise en ligne, nous assurons aussi leur mise à jour pour garantir une sécurité optimale et une évolution en fonction des normes actuelles.</p>
      </div>
    </div>
  </div>
};

export default Philosophy;