import React from 'react';
import HeroBanner from '../components/HeroBanner/HeroBanner';
import Collaborators from '../containers/Collaborators';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Agency = () => {
  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <>
    <HeroBanner remove_contact={true} />
    <Collaborators />
  </>
};

export default Agency;