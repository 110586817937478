import Title from '../components/Title';
import { createUseStyles } from 'react-jss';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Legals = () => {
  // 
  // ─── STYLE INITIALIZATION ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      padding: 20
    },
    content: {
      '@media screen and (min-width:1000px)': {
        padding: '30px 60px',
        margin: 'auto',
      }
    },
    links: {
      color: 'var(--primary)'
    }
  })
  const classes = useStyle()

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.container}>
    <div className={classes.content} style={{ maxWidth: '625px' }}>
      <Title tag="h2" text_regular="MENTIONS" text_strong="LÉGALES" align="flex-start" />
      <h2>Éditeur du site internet</h2>
      <p>SARL OLWE Groupe au capital de 500€<br />
        5 chemin des bûcherons, 68100 MULHOUSE<br />
        Numéro de téléphone : 0601754787<br />
        Adresse mail : contact@olwe.fr<br />
        Numéro SIRET : 899 338 867 00018<br />
      </p>
      <p>Directeur de la publication : Florian HOUAL, Gérant.</p>

      <h2>Hébergement | Contact technique :</h2>
      <p>SARL OLWE Groupe<br />5 chemin des bûcherons, 68100 MULHOUSE</p>

      <h2>Hébergement : </h2>
      <p>OVH<br />
        SAS au capital de 10 069 020€<br />
        RCS Lille Métropole 424 761 419 0004<br />
        Code APE 2620Z<br />
        N° TVA : FR 22 424 761 419<br />
        Siège social : 2 rue Kellermann - 59100 Roubaix - France.</p>

      <h2>Crédits : </h2>
      <p>Visuels : <a className={classes.links} target="blank" href='https://unsplash.com/'>www.unsplash.com</a></p>
    </div>
  </div>
};

export default Legals;