import React from 'react';
import HeroBanner from '../components/HeroBanner/HeroBanner';
import ServiceSteps from '../components/ServiceSteps';
// import ServiceOthers from '../components/ServiceOthers';
import ServiceWebDetail from '../containers/Services/Web/ServiceWebDetail';
import ContactForm from '../containers/ContactForm/ContactForm';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ServiceWeb = () => {

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <>
    <HeroBanner
      title='<span>Développement</span> sur mesure'
      description='Vous souhaitez vous lancer dans un projet qui vous ressemble et qui se rattache à vos valeurs et à celles de votre entreprise ?
      Vous êtes au bon endroit ! Vous écouter et vous accompagner de A à Z dans la conception de vos projets est notre priorité.'
    />
    <ServiceWebDetail />
    <ServiceSteps />
    {/* <ServiceOthers active={['web']} /> */}
    <ContactForm theme='dark' />
  </>
};

export default ServiceWeb;